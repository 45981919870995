import { IFigmaFilePage } from "@shared/types/FigmaFile";
import {
  ITextItem,
  ZTextItemCreationSource,
  ZTextItemPlural,
  ZTextItemStatus,
  ZTextItemVariable,
  ZTextItemVariant,
  ZTipTapRichText,
} from "@shared/types/TextItem";
import { z } from "zod";
import { ZDittoProject, ZFramePreviewData } from "../DittoProject";
import { IFigmaTextNode } from "../FigmaTextNode";
import { ZObjectId } from "../lib";

export const ZCreateDittoProject = z.object({
  projectName: z.string(),
  folderId: ZObjectId.optional(),
  figmaData: z
    .object({
      fileId: z.string(),
      branchId: z.string().nullable().optional(),
      dittoComponentNodeId: z.string(),
      dittoComponentKey: z.string(),
    })
    .optional(),
});

export type ICreateDittoProject = z.infer<typeof ZCreateDittoProject>;

const ZDittoTextItemsData = z.array(
  z.object({
    _id: ZObjectId,
    sortKey: z.string(),
  })
);

const ZDittoBlockData = z.object({
  _id: ZObjectId.nullable(),
  name: z.string().nullable(),
  textItems: ZDittoTextItemsData,
  allTextItems: ZDittoTextItemsData,
  variantIds: z.array(ZObjectId).optional(),
});

export type IDittoBlockData = z.infer<typeof ZDittoBlockData>;

// Note: There are more fields in the Folder collection.
// This is the subset that is returned with a ZDittoProjectData object.
// If additional fields become relevant, add them.
const ZDittoProjectFolderData = z.object({
  _id: ZObjectId,
  name: z.string(),
  invite_only: z.boolean(),
});

export type IDittoProjectFolderData = z.infer<typeof ZDittoProjectFolderData>;

const ZDittoProjectData = ZDittoProject.extend({
  blocks: z.array(ZDittoBlockData),
  folder: ZDittoProjectFolderData.nullable(),
  hiddenBlocksCount: z.number(), // How many blocks are completely hidden (have no visible text items)
  hiddenBlockTextItemCount: z.number(), // How many text items the hidden blocks contain. Does not include hidden text items in root or visible blocks
  hiddenRootTextItemsCount: z.number(), // How many text items are in the root of the project but are hidden
  linkedFigmaTextNodesToTextItemId: z.record(z.string(), ZObjectId.nullable()), // Map of Figma text node IDs to Ditto text item IDs. Used for quick lookups when checking if a figma text node is linked to a text item.
});

export type IDittoProjectData = z.infer<typeof ZDittoProjectData>;

export const ZNewTextItem = z.object({
  _id: z.string().optional(),
  richText: ZTipTapRichText,
  blockId: z.string().nullable().optional(),
  notes: z.string().optional(),
  tags: z.array(z.string()).optional(),
  status: ZTextItemStatus.optional(),
  assignee: z.string().optional(),
  instances: z
    .array(
      z.object({
        position: z.object({
          x: z.number(),
          y: z.number(),
          width: z.number(),
          height: z.number(),
        }),
        lastReconciledRichText: ZTipTapRichText.nullable().optional(),
        figmaNodeId: z.string(),
      })
    )
    .optional(),
  variables: z.array(ZTextItemVariable).optional(),
  variants: z.array(ZTextItemVariant).optional(),
  plurals: z.array(ZTextItemPlural).optional(),
});

export type INewTextItem = z.infer<typeof ZNewTextItem>;

export const ZCreateTextItemsProps = z.object({
  textItems: z.array(ZNewTextItem),
  source: ZTextItemCreationSource,
});

export const ZCreateTextItemsFromPluginProps = ZCreateTextItemsProps.extend({
  pageId: z.string(),
  fileId: z.string(),
  branchId: z.string().nullable(),
  linkDuplicates: z.boolean().optional(),
  createBlock: z.boolean().optional(),
});
export type ICreateTextItemsFromPluginProps = z.infer<typeof ZCreateTextItemsFromPluginProps>;

export const ZCreateTextItemsFromWebAppProps = ZCreateTextItemsProps.extend({
  source: ZTextItemCreationSource,
  addToStart: z.boolean().optional(),
});
export type ICreateTextItemsFromWebAppProps = z.infer<typeof ZCreateTextItemsFromWebAppProps>;

export type ICreateTextItemsProps = z.infer<typeof ZCreateTextItemsProps>;

// MARK: - Creating Blocks

export const ZNewBlock = z.object({
  _id: z.string().optional(),
  name: z.string().optional(),
  textItems: ZDittoTextItemsData.optional(),
});

export type INewBlock = z.infer<typeof ZNewBlock>;

export const ZBlockCreationSource = z.union([
  z.literal("plugin_selection"),
  z.literal("plugin_import"),
  z.literal("web_app"),
]);
export const ZCreateBlocksProps = z.object({
  blocks: z.array(ZNewBlock),
  source: ZBlockCreationSource,
  atIndex: z.number().optional(),
});
export type ICreateBlocksProps = z.infer<typeof ZCreateBlocksProps>;

// MARK: - Moving Text Items

export const ZMoveTextItemsAction = z.object({
  textItemIds: z.array(ZObjectId),
  blockId: z.string().optional().nullable(),
  before: z.string().optional(),
  after: z.string().optional(),
});

export type IMoveTextItemsAction = z.infer<typeof ZMoveTextItemsAction>;

export const ZMoveTextItemsProps = z.object({
  actions: z.array(ZMoveTextItemsAction),
});

export type IMoveTextItemsProps = z.infer<typeof ZMoveTextItemsProps>;

export const ZGetFramePreviewsData = z.object({
  framePreviewMap: z.record(z.string(), ZFramePreviewData),
  textItemIdsToTopLevelFrameNodeIds: z.record(z.string(), z.array(z.string())),
  textNodeIdsToTopLevelFrameNodeIds: z.record(z.string(), z.string()),
});

export type IGetFramePreviewsData = z.infer<typeof ZGetFramePreviewsData>;

export interface ITextItemsMapData {
  [textItemId: string]: ITextItem;
}

export interface ISyncedTextNodesMapData {
  [nodeId: string]: IFigmaTextNode;
}

export const errors = {
  NO_FIGMA_FILE_ASSOCIATED_WITH_PROJECT: "No Figma file associated with Ditto project",
};

export interface GetSyncedFigmaPagesResponse {
  pages: IFigmaFilePage[];
}
