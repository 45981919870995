import { userAtom } from "@/stores/Auth";
import { figmaSyncActionAtom, figmaSyncErrorMessageAtom, isFigmaSyncingAtom } from "@/stores/FigmaSync";
import { projectFigmaFileIdAtom, projectFigmaFileLinkAtom, projectIdAtom } from "@/stores/Project";
import {
  filteredFramePreviewsAtom,
  frameDataIsRefreshingAtom,
  pinnedNodeIdAtom,
  setPinnedNodeIdOverrideAtom,
} from "@/stores/ProjectDesignPreviews";
import { onTextItemClickActionAtomFamily, selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import Badge from "@ds/atoms/Badge";
import LoadingSpinner from "@ds/atoms/LoadingSpinner";
import SeparatorDot from "@ds/atoms/SeparatorDot";
import Text from "@ds/atoms/Text";
import ChangeIndicator from "@ds/molecules/ChangeIndicator";
import Scrollbar from "@ds/molecules/Scrollbar";
import Tooltip from "@ds/molecules/Tooltip";
import { IFramePreviewDataWithSelectionData, ITextNodeWithSelectionData } from "@shared/types/DittoProject";
import logger from "@shared/utils/logger";
import { getNoSecondsFormatter } from "@shared/utils/timeAgoFormatters";
import classNames from "classnames";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTimeago from "react-timeago";
import style from "./style.module.css";

import { NS_PROJECT_ID_KEY } from "@/store/FigmaAuthContext";
import Button from "@ds/atoms/Button";
interface DesignPreviewsProps {
  className?: string;
}

const HIGHLIGHT_BOX_PADDING = 5;

enum DesignPreviewsStates {
  MISSING_INVALID_TOKEN = "MISSING_INVALID_TOKEN",
  SYNC_ERROR = "SYNC_ERROR",
  PROJECT_NOT_CONNECTED = "PROJECT_NOT_CONNECTED",
  NO_PREVIEWS_FOR_SELECTION = "NO_PREVIEWS_FOR_SELECTION",
  FETCHING_PREVIEWS = "FETCHING_PREVIEWS",
  FINISHED = "FINISHED",
}

const DESIGN_PREVIEWS_SCROLL_CONTAINER_ID = "design-previews-scroll-container";
const DESIGN_PREVIEWS_CONTAINER_ID = "design-previews-container";

const DesignPreviews = (props: DesignPreviewsProps) => {
  const projectId = useAtomValue(projectIdAtom);
  const previews = useAtomValue(filteredFramePreviewsAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const user = useAtomValue(userAtom, { store: getDefaultStore() });
  const syncFigma = useSetAtom(figmaSyncActionAtom);
  const isFigmaSyncing = useAtomValue(isFigmaSyncingAtom);
  const frameDataIsRefreshing = useAtomValue(frameDataIsRefreshingAtom);
  const figmaSyncErrorMessage = useAtomValue(figmaSyncErrorMessageAtom);
  const figmaFileId = useAtomValue(projectFigmaFileIdAtom);

  const [longFetch, setLongFetch] = useState(false);

  useEffect(
    function syncFigmaOnMount() {
      syncFigma();
    },
    [syncFigma]
  );

  useEffect(
    function stillFetchingTimeout() {
      setTimeout(() => {
        setLongFetch(true);
      }, 6000);
    },
    [longFetch, isFigmaSyncing]
  );

  const designPreviewsState = useMemo(() => {
    if (user?.isFigmaAuthenticated === false || figmaSyncErrorMessage === "INVALID_TOKEN") {
      return DesignPreviewsStates.MISSING_INVALID_TOKEN;
    }

    if (figmaSyncErrorMessage) {
      return DesignPreviewsStates.SYNC_ERROR;
    }

    if (isFigmaSyncing || frameDataIsRefreshing) {
      return DesignPreviewsStates.FETCHING_PREVIEWS;
    }

    if (selectedTextItemIds.length >= 0) {
      return DesignPreviewsStates.NO_PREVIEWS_FOR_SELECTION;
    }

    if (!figmaFileId) {
      return DesignPreviewsStates.PROJECT_NOT_CONNECTED;
    }

    return DesignPreviewsStates.FINISHED;
  }, [
    user?.isFigmaAuthenticated,
    figmaSyncErrorMessage,
    selectedTextItemIds.length,
    frameDataIsRefreshing,
    isFigmaSyncing,
    figmaFileId,
  ]);

  return (
    <div className={classNames(style.designPreviewsContainer, props.className)}>
      <Scrollbar className={style.scrollWrapper} id={DESIGN_PREVIEWS_SCROLL_CONTAINER_ID}>
        <div className={style.previews} id={DESIGN_PREVIEWS_CONTAINER_ID}>
          {previews.length === 0 && (
            <div className={style.emptyState}>
              {designPreviewsState === DesignPreviewsStates.MISSING_INVALID_TOKEN && (
                <div className={style.callout}>
                  <div className={style.textContainer}>
                    <Text color="primary" weight="medium">
                      Your Ditto account isn’t connected with Figma.
                    </Text>
                    <Text size="small" color="secondary">
                      Connect with Figma to allow access for Ditto to fetch design previews for this project.
                    </Text>
                  </div>

                  <Button
                    level="primary"
                    size="small"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/account/user?reauthorize_figma=true&${NS_PROJECT_ID_KEY}=${projectId}`,
                        "_blank"
                      );
                    }}
                  >
                    Connect with Figma
                  </Button>
                </div>
              )}

              {designPreviewsState === DesignPreviewsStates.SYNC_ERROR && (
                <div className={style.callout}>
                  <div className={style.textContainer}>
                    <Text color="primary" weight="medium">
                      Couldn't fetch updated design previews
                    </Text>
                    <Text inline size="small" color="secondary">
                      Something went wrong while trying with Figma to fetch updated design previews. We’ll retry in a
                      few moments, or you can{" "}
                      <Text asLink size="small" color="action" onClick={syncFigma}>
                        try again.
                      </Text>
                    </Text>
                  </div>
                </div>
              )}

              {designPreviewsState === DesignPreviewsStates.FETCHING_PREVIEWS && (
                <div className={style.flexCol}>
                  <Text color="primary" weight="medium">
                    {longFetch ? "Still fetching" : "Fetching"} design previews...
                  </Text>
                  <Text size="small" color="secondary">
                    This may take a few minutes.
                  </Text>
                </div>
              )}

              {designPreviewsState === DesignPreviewsStates.NO_PREVIEWS_FOR_SELECTION && (
                <div className={style.flexCol}>
                  <Text color="primary" weight="medium">
                    No design previews available for selected text items
                  </Text>
                  <Text size="small" color="secondary">
                    Link these text items to Figma to see previews here.
                  </Text>
                </div>
              )}
            </div>
          )}

          {designPreviewsState === DesignPreviewsStates.PROJECT_NOT_CONNECTED && (
            <div className={style.flexCol}>
              <Text color="primary" weight="medium">
                No design previews available
              </Text>
              <Text size="small" color="secondary">
                Connect your project to Figma to enable design previews.
              </Text>
            </div>
          )}

          {previews.map((preview) => (
            <DesignPreview
              key={preview.frameNodeId}
              frameNodeId={preview.frameNodeId}
              previewUrl={preview.previewUrl}
              lastUpdated={preview.lastUpdated}
              textNodesToHighlight={preview.textNodesToHighlight}
              position={preview.position}
              frameName={preview.frameName}
              frameIsModified={preview.frameIsModified}
            />
          ))}
        </div>
      </Scrollbar>

      {designPreviewsState === DesignPreviewsStates.MISSING_INVALID_TOKEN && (
        <div className={classNames(style.callout, style.floating)}>
          <div className={style.textContainer}>
            <Text color="invert" weight="medium">
              Your Ditto account isn’t connected with Figma.
            </Text>
            <Text size="small" color="secondary">
              Connect with Figma to allow access for Ditto to fetch design previews for this project.
            </Text>
          </div>

          <Button
            level="primary"
            size="small"
            onClick={() => {
              window.open(
                `${window.location.origin}/account/user?reauthorize_figma=true&${NS_PROJECT_ID_KEY}=${projectId}`,
                "_blank"
              );
            }}
          >
            Connect with Figma
          </Button>
        </div>
      )}

      {designPreviewsState === DesignPreviewsStates.SYNC_ERROR && (
        <div className={classNames(style.callout, style.floating)}>
          <div className={style.textContainer}>
            <Text color="invert" weight="medium">
              Couldn’t fetch updated design previews
            </Text>
            <Text inline size="small" color="secondary">
              Something went wrong while trying with Figma to fetch updated design previews. We’ll retry in a few
              moments, or you can{" "}
              <Text asLink size="small" color="action" onClick={syncFigma}>
                try again.
              </Text>
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

DesignPreviews.Fallback = () => {
  return (
    <div className={style.designPreviewsContainer}>
      <div className={style.previews}>
        <Skeleton height={400} className={style.previewSkeleton} />
        <Skeleton height={400} className={style.previewSkeleton} />
        <Skeleton height={400} className={style.previewSkeleton} />
      </div>
    </div>
  );
};

interface Position {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface DesignPreviewProps extends IFramePreviewDataWithSelectionData {}

const CONTAINER_PADDING = 16;

function DesignPreview(props: DesignPreviewProps) {
  const figmaFileLink = useAtomValue(projectFigmaFileLinkAtom);
  const isFigmaSyncing = useAtomValue(isFigmaSyncingAtom);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imgError, setImgError] = useState<{ exists: boolean; status: string | number | null }>({
    exists: false,
    status: null,
  });
  const [imageRenderDimensions, setImageRenderDimensions] = useState<Position | null>(null);
  const [imageContainerDimensions, setImageContainerDimensions] = useState<Position | null>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const imageMaxWidth = useMemo(() => {
    if (!imageContainerDimensions) return null;
    const containerWidth = imageContainerDimensions.width - CONTAINER_PADDING * 2;

    return Math.min(containerWidth, props.position.width);
  }, [imageContainerDimensions, props.position.width]);

  const frameLink = useMemo(() => {
    if (!figmaFileLink) return null;
    return `${figmaFileLink}?node-id=${props.frameNodeId.replace(":", "-")}&node-type=frame`;
  }, [figmaFileLink, props.frameNodeId]);

  function handleImgLoaded() {
    updateImageRenderDimensions();
    setImageLoaded(true);
    setImgError({ exists: false, status: null });
  }

  const handleImgLoadingError = async (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.persist();
    if (!props.previewUrl) return;
    try {
      const imgResponse = await fetch(props.previewUrl);
      if (!imgResponse.ok) {
        setImgError({ exists: true, status: imgResponse.status });
      } else {
        setImgError({ exists: true, status: null });
      }
    } catch (e) {
      logger.error("Failed to fetch image preview", { context: { url: props.previewUrl } }, e);
      setImgError({ exists: true, status: null });
    }
  };

  function updateImageRenderDimensions() {
    if (imgRef.current) {
      setImageRenderDimensions({
        x: imgRef.current.offsetLeft,
        y: imgRef.current.offsetTop,
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      });
    }
  }

  function updateImageContainerDimensions() {
    if (imageContainerRef.current) {
      setImageContainerDimensions({
        x: imageContainerRef.current.offsetLeft,
        y: imageContainerRef.current.offsetTop,
        width: imageContainerRef.current.offsetWidth,
        height: imageContainerRef.current.offsetHeight,
      });
    }
  }

  useEffect(function addResizeListener() {
    function handleResize() {
      updateImageRenderDimensions();
      updateImageContainerDimensions();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(
    function updateImageRenderDimensionsOnMount() {
      if (imgRef.current?.offsetWidth && imgRef.current?.offsetHeight) updateImageRenderDimensions();

      updateImageContainerDimensions();
    },
    [imgRef.current?.offsetWidth, imgRef.current?.offsetHeight]
  );

  useEffect(
    function loadImage() {
      if (!props.previewUrl || !imgRef.current) return;

      if (imgRef.current?.src !== props.previewUrl) setImageLoaded(false);

      const downloadingImage = new Image();

      downloadingImage.onload = function (this: HTMLImageElement) {
        if (imgRef.current) {
          imgRef.current.src = this.src;
        }

        setImageLoaded(true);
        setImgError({ exists: false, status: null });
      };

      downloadingImage.src = props.previewUrl;
    },
    [props.previewUrl]
  );

  const scaledTextNodeHighlights: ITextNodeWithSelectionData[] = useMemo(() => {
    if (!imageRenderDimensions) return [];

    const scaleFactor = imageRenderDimensions.width / props.position.width;

    return props.textNodesToHighlight.map((textNode) => {
      const { x, y, width, height } = getTextNodeRelativePosition(props.position, textNode.position);
      const scaledX = x * scaleFactor - HIGHLIGHT_BOX_PADDING;
      const scaledY = y * scaleFactor - HIGHLIGHT_BOX_PADDING;
      const scaledWidth = width * scaleFactor + HIGHLIGHT_BOX_PADDING * 2;
      const scaledHeight = height * scaleFactor + HIGHLIGHT_BOX_PADDING * 2;

      return {
        ...textNode,
        position: {
          x: scaledX,
          y: scaledY,
          width: scaledWidth,
          height: scaledHeight,
        },
      };
    });
  }, [imageRenderDimensions, props.position, props.textNodesToHighlight]);

  return (
    <div className={style.preview}>
      <div className={style.header}>
        <div className={style.nameAndBadge}>
          {props.frameIsModified && (
            <Tooltip
              type="invert"
              size="sm"
              content="This frame contains text which has been modified in Ditto, but not yet synced to Figma."
              textAlign="center"
            >
              <Badge size="sm" color="blue-inverted" style={{ cursor: "default" }}>
                Modified
              </Badge>
            </Tooltip>
          )}
          <Text size="small" color="primary" weight="medium">
            {props.frameName}
          </Text>
        </div>

        <div className={style.details}>
          {isFigmaSyncing ? (
            <Tooltip type="invert" size="sm" content={<FetchedAgo lastUpdated={props.lastUpdated} />}>
              <div className={style.fetchingPreview}>
                <LoadingSpinner size="micro" />
                <Text size="small" color="tertiary" weight="light">
                  Fetching preview
                </Text>
              </div>
            </Tooltip>
          ) : (
            <FetchedAgo lastUpdated={props.lastUpdated} />
          )}
          <SeparatorDot style={{ backgroundColor: "#d9d9d9" }} />
          <Text
            asLink
            size="small"
            color="tertiary"
            weight="medium"
            onClick={() => frameLink && window.open(frameLink, "_blank")}
          >
            Open in Figma
          </Text>
        </div>
      </div>

      <div className={style.imageContainer} ref={imageContainerRef}>
        {!imageLoaded && (
          <div className={style.previewLoading}>
            <LoadingSpinner />
          </div>
        )}
        <div className={style.imageWrapper}>
          <div className={style.overlay}>
            {scaledTextNodeHighlights.map((highlight) => (
              <TextNodeHighlight key={highlight.nodeId} highlight={highlight} />
            ))}
          </div>
          <img
            ref={imgRef}
            loading="lazy"
            className={style.image}
            onLoad={handleImgLoaded}
            onError={handleImgLoadingError}
            src={props.previewUrl}
            alt="preview"
            style={{ maxWidth: `${imageMaxWidth}px` }}
          />
        </div>
      </div>
    </div>
  );
}

function FetchedAgo(props: { lastUpdated: string }) {
  return (
    <Text inline size="small" color="tertiary" weight="light">
      Fetched{" "}
      <ReactTimeago
        date={props.lastUpdated}
        minPeriod={30}
        formatter={getNoSecondsFormatter("less than a minute ago")}
      />
    </Text>
  );
}

function TextNodeHighlight(props: { highlight: ITextNodeWithSelectionData }) {
  const { highlight } = props;
  const onTextItemClick = useSetAtom(onTextItemClickActionAtomFamily(highlight.pluginData?.textItemId ?? ""));
  const setPinnedNodeIdOverride = useSetAtom(setPinnedNodeIdOverrideAtom);
  const pinnedNodeId = useAtomValue(pinnedNodeIdAtom);

  function handleHighlightClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!highlight.pluginData?.textItemId) return;
    onTextItemClick({ richText: highlight.richText, e, skipInlineEdit: true });
  }

  const handleChangeIndicatorClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (pinnedNodeId === highlight.nodeId) setPinnedNodeIdOverride(null);
      else setPinnedNodeIdOverride(highlight.nodeId);
    },
    [highlight.nodeId, pinnedNodeId, setPinnedNodeIdOverride]
  );

  return (
    <div
      className={classNames(style.highlight, highlight.isSelected && style.selected)}
      onClick={handleHighlightClick}
      style={{
        left: highlight.position.x,
        top: highlight.position.y,
        width: highlight.position.width,
        height: highlight.position.height,
      }}
    >
      {highlight.diff && (
        <ChangeIndicator
          className={style.changeIndicator}
          textBefore={highlight.diff.textBefore}
          textAfter={highlight.diff.textAfter}
          changeTime={highlight.diff.textItemUpdatedAt}
          pinned={pinnedNodeId === highlight.nodeId}
          onClick={handleChangeIndicatorClick}
          scrollContainerId={DESIGN_PREVIEWS_SCROLL_CONTAINER_ID}
          previewsContainerId={DESIGN_PREVIEWS_CONTAINER_ID}
        />
      )}
    </div>
  );
}

// We store both the frame and text node positions as *absolute* positions -- this function converts
// a text node's position to a position relative to the frame.
function getTextNodeRelativePosition(framePos: Position, textNodePos: Position) {
  return {
    x: textNodePos.x - framePos.x,
    y: textNodePos.y - framePos.y,
    width: textNodePos.width,
    height: textNodePos.height,
  };
}

export default DesignPreviews;
