import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import classNames from "classnames";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import SearchHighlightedText from "../SearchHighlightedText";
import style from "./index.module.css";

interface IInlineEditableNameProps {
  className?: string;
  style?: React.CSSProperties;

  textStyleClass?: string;

  /**
   * Optional string to highlight a portion of the name (e.g. for search results).
   */
  highlightedPhrase?: string;

  /**
   * Initial name value of editable name input.
   */
  name: string;

  /**
   * Handler for saving current edits to the name.
   */
  onSave: (name: string) => void;

  // Callback executed after canceling out of a rename
  onReset?: () => void;

  // Callback executed every time the name input changes
  onChange?: (name: string) => void;

  /**
   * Option to replace the default onBlur functionality, which is to call onSave.
   */
  onBlur?: () => void;

  // Callback when input is focused
  onFocus?: () => void;

  resetOnBlur?: boolean;

  /**
   * Flag for whether we allow saving when current name is empty.
   */
  emptyNameAllowed?: boolean;

  /**
   * Placeholder text for empty text edit field.
   */
  placeholder?: string;

  /**
   * Styled presets.
   */
  variant?: "default" | "code" | "header" | "breadcrumb";

  forceHoverState?: boolean;

  /**
   * Styles that will be applied to both the display text and the text in the input.
   */
  textStyles?: React.CSSProperties;

  autofocus?: boolean;
}

export function InlineEditableName(props: IInlineEditableNameProps) {
  const { placeholder, name, onSave, variant = "default" } = props;
  const [editing, setEditing] = useState(props.autofocus ?? false);
  const [currName, setCurrName] = useState(name);
  const [contentWidth, setContentWidth] = useState(0);
  const [showInput, setShowInput] = useState(editing);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const displayRef = React.useRef<HTMLDivElement>(null);
  const hiddenSpanRef = React.useRef<HTMLSpanElement>(null);

  /**
   * We want our input to be able to save or reset on blur, depending on our props. But if the user clicks the
   * checkmark or X icons, we want to make sure those handlers take precedence, and we don't subsequently run the blur
   * logic.
   */
  const exitFunctionHasRun = React.useRef(false);

  function startEditing() {
    exitFunctionHasRun.current = false;

    setCurrName(name);
    setEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }

  function save() {
    if (exitFunctionHasRun.current) return;
    exitFunctionHasRun.current = true;

    setEditing(false);
    setShowInput(false);
    onSave(currName);
    inputRef.current?.blur();
  }

  function reset() {
    if (exitFunctionHasRun.current) return;
    exitFunctionHasRun.current = true;

    setEditing(false);
    setCurrName(name);

    if (props.onReset) props.onReset();
    // imperatively calling inputRef.current?.blur() here doesn't trigger the blur handler
    // so calling the blur handler here directly (e.g. used for cleanup for drag and drop state)
    inputRef.current?.blur();
    handleBlurInput();
  }

  function onClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (!editing) startEditing();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      save();
    } else if (e.key === "Escape") {
      reset();
      // This prevents the global unselect selection on escape behavior since we just want the reset behavior.
      e.stopPropagation();
    } else if (e.key === "a") {
      if (navigator.userAgent.includes("Mac")) {
        if (e.metaKey) {
          e.preventDefault();
          inputRef.current?.select();
        }
      } else if (e.ctrlKey) {
        e.preventDefault();
        inputRef.current?.select();
      }
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCurrName(e.target.value);
    if (props.onChange) props.onChange(e.target.value);
  }

  // Default blur functionality is to save, but this can be customized
  function handleBlurInput(e?: React.FocusEvent<HTMLInputElement>) {
    if (isEmpty(currName) && !props.emptyNameAllowed) {
      reset();
      return;
    }

    setEditing(false);
    setShowInput(false);
    if (props.onBlur) props.onBlur();
    if (props.resetOnBlur) reset();
    else save();
  }

  function handleCheckmarkClick(e?: React.MouseEvent<HTMLButtonElement>) {
    if (isEmpty(currName) && !props.emptyNameAllowed) {
      reset();
      return;
    }
    save();
  }

  function handleCloseClick(e?: React.MouseEvent<HTMLButtonElement>) {
    reset();
  }

  function isEmpty(name: string) {
    return name.trim() === "";
  }

  // we want the input to be exactly the same width as the display text
  useEffect(function initializeContentWidth() {
    document.fonts.ready.then(() => {
      if (hiddenSpanRef.current) {
        const contentWidth = hiddenSpanRef.current.scrollWidth;
        setContentWidth(Math.ceil(contentWidth) + 1);
      }
    });
  }, []);

  useLayoutEffect(
    function recalculateContentWidth() {
      if (hiddenSpanRef.current) {
        const contentWidth = hiddenSpanRef.current.scrollWidth;
        setContentWidth(Math.ceil(contentWidth) + 1);
      }
    },
    [currName]
  );

  /**
   * This is hacky, but there are some situations where currName is initialized with an improper value,
   * and we need to make sure it's always being reset when its props change.
   */
  useEffect(
    function resetCurrNameOnPropsChange() {
      setCurrName(props.name);
    },
    [props.name, editing]
  );

  const onMouseOver = useCallback(function _onMouseOver() {
    setShowInput(true);
  }, []);

  const onMouseLeave = useCallback(
    function _onMouseLeave() {
      if (!editing) setShowInput(false);
    },
    [editing]
  );

  const showEditIcon = (showInput || props.forceHoverState) && !editing;

  return (
    <div
      style={props.style}
      className={classNames(style.InlineEditableNameWrapper, props.className, {
        [style.editing]: editing,
        [style[`variant-${variant}`]]: true,
      })}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      data-testid="inline-editable-name"
    >
      <div className={style.textWrapper}>
        {showInput && (
          <>
            <input
              autoFocus={props.autofocus}
              className={classNames(style.input, props.textStyleClass)}
              placeholder={placeholder}
              value={currName}
              onBlur={handleBlurInput}
              onChange={handleInputChange}
              onKeyDownCapture={handleKeyDown}
              onFocus={props.onFocus}
              onClick={onClick}
              ref={inputRef}
              style={{ width: `${contentWidth}px`, ...props.textStyles }}
            />
          </>
        )}
        {!showInput && (
          <Text
            onClick={onClick}
            className={classNames(style.InlineEditableNameLabel, props.textStyleClass, {
              [style.placeholder]: !currName,
            })}
            ref={displayRef}
            style={{ width: `${contentWidth}px`, ...props.textStyles }}
          >
            {props.name === "" && props.placeholder}
            {props.name !== "" && <SearchHighlightedText text={currName} highlightedPhrase={props.highlightedPhrase} />}
          </Text>
        )}

        {/* Invisible hidden span used to calculate dynamic input width */}
        <span
          ref={hiddenSpanRef}
          className={classNames(style.InlineEditableNameLabel, props.textStyleClass, style.hidden)}
          style={{ ...props.textStyles }}
        >
          {currName || props.placeholder}
        </span>
      </div>
      {showEditIcon && (
        <Button type="icon" level="secondary" onClick={startEditing}>
          <EditIcon />
        </Button>
      )}
      {editing && (
        <div className={style.editIconsWrapper}>
          <Button type="icon" level="secondary" onMouseDown={handleCheckmarkClick}>
            <CheckIcon className={style.checkIcon} />
          </Button>
          <Button type="icon" level="secondary" onMouseDown={handleCloseClick}>
            <CloseIcon className={style.closeIcon} />
          </Button>
        </div>
      )}
    </div>
  );
}

export default InlineEditableName;
