import useSegment from "@/hooks/useSegment";
import {
  deleteTextItemActionAtom,
  editableHasChangesAtom,
  resetTextItemVariantChangesActionAtom,
  stopInlineEditingActionAtom,
} from "@/stores/Editing";
import { modalAtom } from "@/stores/Modals";
import { projectIdAtom } from "@/stores/Project";
import { designPreviewToggledAtom } from "@/stores/ProjectFiltering";
import {
  derivedOnlySelectedTextItemAtom,
  resetCommentEditsActionAtom,
  setSelectedTextIdsActionAtom,
} from "@/stores/ProjectSelection";
import Comments from "@/views/NS/ProjectNS/components/DetailsPanel/Comments";
import DeleteTextItem from "@/views/NS/ProjectNS/components/DetailsPanel/DeleteTextItem";
import DeveloperId from "@/views/NS/ProjectNS/components/DetailsPanel/DeveloperId";
import Library from "@/views/NS/ProjectNS/components/DetailsPanel/Library";
import MetadataPanel from "@/views/NS/ProjectNS/components/DetailsPanel/Metadata";
import InstanceCount from "@ds/molecules/InstanceCount";
import * as SegmentEvents from "@shared/segment-event-names";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { Suspense } from "react";

interface TextItemDetailsProps {
  className?: string;
}

const TextItemDetails = (props: TextItemDetailsProps) => {
  const { track } = useSegment();
  const [designPreviewToggled, setDesignPreviewToggled] = useAtom(designPreviewToggledAtom);
  const setSelectedTextItemIds = useSetAtom(setSelectedTextIdsActionAtom);
  const setEditableHasChanges = useSetAtom(editableHasChangesAtom);
  const resetTextItemVariantChanges = useSetAtom(resetTextItemVariantChangesActionAtom);
  const resetCommentEdits = useSetAtom(resetCommentEditsActionAtom);
  const stopInlineEditing = useSetAtom(stopInlineEditingActionAtom);
  const onlySelectedTextItem = useAtomValue(derivedOnlySelectedTextItemAtom);

  const projectId = useAtomValue(projectIdAtom);
  const deleteTextItemAction = useSetAtom(deleteTextItemActionAtom);

  const setModal = useSetAtom(modalAtom);

  const instanceCount = onlySelectedTextItem?.integrations?.figmaV2?.instances?.length ?? 0;

  function showDeleteTextItemModal() {
    setModal({
      headline: "Delete text item?",
      content: `The text item and all of its data will be deleted, and linked text layers
        will be unlinked. This can't be undone.`,
      actionText: "Delete",
      onAction: deleteTextItem,
      onOpenChange: (open) => {
        if (open) return;
        // cleanup logic for cancel
        setModal(null);
      },
      type: "danger",
    });
  }

  const deleteTextItem = async () => {
    if (projectId === null || !onlySelectedTextItem?._id) {
      setModal(null);
      return;
    }

    deleteTextItemAction(onlySelectedTextItem._id);

    /**
     * Discard all in-progress edits to the deleted text item
     */
    setEditableHasChanges(false);
    resetTextItemVariantChanges();
    resetCommentEdits();
    stopInlineEditing({ skipConfirmation: true });
    setSelectedTextItemIds([]);
    setModal(null);
  };

  const previewBannerClick = () => {
    setDesignPreviewToggled(true);

    track({ event: SegmentEvents.DESIGN_PREVIEW_OPENED, properties: { from: "banner" } });
  };

  return (
    <>
      {instanceCount > 0 && (
        <Suspense fallback={<div>Loading Instances...</div>}>
          {!designPreviewToggled && (
            <InstanceCount
              content={`${instanceCount} instance${instanceCount === 1 ? "" : "s"} in design`}
              actionText="Preview"
              onActionClick={previewBannerClick}
            />
          )}
        </Suspense>
      )}
      <Suspense fallback={<div>Loading Metadata...</div>}>
        <MetadataPanel />
      </Suspense>

      {/* These elements should only be rendered if exactly one text item is selected */}
      {!!onlySelectedTextItem && (
        <>
          <Suspense fallback={<></>}>
            <Comments />
          </Suspense>
          <Library />
          <DeveloperId />
          <DeleteTextItem onClick={showDeleteTextItemModal} />
        </>
      )}
    </>
  );
};

export default TextItemDetails;
