import classNames from "classnames";
import React, { forwardRef } from "react";
import { useNativeProps } from "../../useNativeProps";
import style from "./index.module.css";

export type TextSize = "base" | "small" | "micro" | "large" | "h1" | "xl";
export type TextColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "placeholder"
  | "invert"
  | "code"
  | "action"
  | "purple"
  | "positive"
  | "warning"
  | "danger"
  | "info"
  | "template"
  | "variable";

export interface ITextProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  color?: TextColor;
  weight?: "light" | "base" | "medium" | "strong";
  className?: string;
  size?: TextSize;
  disabled?: boolean;
  textAlign?: "left" | "center" | "right" | "justify";
  /**
   * Flag to render text span inline.
   */
  inline?: boolean;
  /**
   * Flag to set text span to fit content width.
   */
  fitContent?: boolean;
  asLink?: boolean;
}

// TODO: typesafe text variations for different headers, font weights, etc
const Text = forwardRef<HTMLSpanElement, ITextProps>(function Text(props: ITextProps, forwardedRef) {
  const nativeProps = useNativeProps<HTMLDivElement, ITextProps>(props, {
    weight: true,
    disabled: true,
    inline: true,
    fitContent: true,
    size: true,
    asLink: true,
    textAlign: true,
  });
  const size = props.size || "base";
  const color = props.color || "primary";
  const weight = props.weight || "base";
  const inline = props.inline ?? false;
  const textAlign = props.textAlign || "left";

  return (
    <span
      {...nativeProps}
      ref={forwardedRef}
      className={classNames(
        style.TextWrapper,
        {
          [style[`size-${size}`]]: size,
          [style["disabled"]]: props.disabled,
          [style[`color-${color}`]]: color,
          [style[`weight-${weight}`]]: weight,
          [style[`align-${textAlign}`]]: textAlign,
          [style.clickable]: !!props.onClick,
          [style.inline]: inline,
          [style["fit-content"]]: props.fitContent ?? false,
          [style.asLink]: props.asLink ?? false,
        },
        props.className
      )}
      onClick={props.onClick}
      data-testid="text"
    >
      {props.children}
    </span>
  );
});

export default Text;
