import { ICommentThread, ICommentType } from "@shared/types/CommentThread";
import { IObjectId } from "@shared/types/lib";
import { createHttpRequest } from "./lib/createHttpRequest";

/**
 * Returns an array of ids of comment threads for a project, paginated by skip and limit if provided.
 */
export const getCommentsByProjectId = createHttpRequest<
  { projectId: string; page: number; pageSize: number; type?: ICommentType },
  IObjectId[]
>({
  method: "get",
  getUrl({ projectId }) {
    return `/comments/project/${projectId}`;
  },
  getConfig({ page, pageSize, type }) {
    return {
      params: { skip: page * pageSize, limit: pageSize, type },
    };
  },
});

/**
 * Returns an array of ids of comment threads for a given text item
 * - optionally paginated (if page and pageSize are provided)
 * - optionally filtered by type
 * - optionally filtered by resolved status
 */
export const getCommentsByTextItemId = createHttpRequest<
  { textItemId: string; page?: number; pageSize?: number; type?: ICommentType; isResolved?: boolean },
  IObjectId[]
>({
  method: "get",
  getUrl({ textItemId }) {
    return `/comments/text-item/${textItemId}`;
  },
  getConfig({ page, pageSize, type, isResolved }) {
    if (page === undefined || pageSize === undefined) {
      return { params: { type, isResolved } };
    }
    return {
      params: { skip: page * pageSize, limit: pageSize, type, isResolved },
    };
  },
});

export const getCommentsByCommentIds = createHttpRequest<
  { commentIds: string[]; projectId?: string; type?: ICommentType },
  ICommentThread[]
>({
  method: "get",
  url: `/comments`,
  getConfig({ commentIds, projectId, type }) {
    return {
      params: { commentIds, projectId, type },
    };
  },
});

/**
 * Creates a new comment thread on a text item
 *
 * This endpoint can also be used to create suggestions
 *   and to comment on library components, but this method
 *   is not configured to handle those cases
 */
export const createCommentThread = createHttpRequest<
  {
    projectId: string;
    commentText: string;
    mentionedUserIds: string[];
    textItemId: string;
    from: string;
  },
  ICommentThread
>({
  method: "post",
  url: `/comments`,
  getConfig({ projectId, commentText, mentionedUserIds, textItemId, from }) {
    return {
      data: {
        comp_id: textItemId,
        first_comment: commentText,
        from,
        mentionedUserIds,
        projectId,
      },
    };
  },
});

export const replyToCommentThread = createHttpRequest<
  { commentThreadId: string; text: string; mentionedUserIds: string[]; from: string },
  ICommentThread
>({
  method: "post",
  getUrl({ commentThreadId }) {
    return `/comments/reply/${commentThreadId}`;
  },
  getConfig({ text, mentionedUserIds, from }) {
    return {
      data: { text, mentionedUserIds, from },
    };
  },
});

export const resolveThread = createHttpRequest<
  { commentThreadId: string; is_resolved: boolean; suggestion_accepted?: boolean; from: string },
  ICommentThread
>({
  method: "post",
  getUrl({ commentThreadId }) {
    return `/comments/resolve/${commentThreadId}`;
  },
  getConfig({ is_resolved, suggestion_accepted, from }) {
    return {
      data: { is_resolved, suggestion_accepted, from },
    };
  },
});
