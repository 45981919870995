/**
 * This file is shared between the front-end and the back-end. It's important
 * that it only imports code that can be safely used on both. Importing modules
 * with side effects that expect a node environment or browser environment
 * will cause errors.
 */
import { REDIS_CHANNELS } from "@shared/common/constants";
import * as SegmentEvents from "@shared/segment-event-names";
import { ZComponentCreationType } from "@shared/types/ActualChange";
import { ZComponentNamesMap } from "@shared/types/Component";
import { ZDittoProjectBlock } from "@shared/types/DittoProject";
import { ZActualComponentStatus, ZTextItemCreationSource } from "@shared/types/TextItem";
import { ZUser } from "@shared/types/User";
import { ZAction } from "@shared/types/figmaSync";
import { ZBlockCreationSource, ZMoveTextItemsAction } from "@shared/types/http/DittoProject";
import { ZCreatableId, ZObjectId } from "@shared/types/lib";
import { z } from "zod";
import slackMessageTypes from "../../services/slack/slackMessageTypes";
import { COMPONENT_ATTACHED_TO_TEXT_ITEMS } from "../segment-event-names";
import { createDittoEvent, filterOutSampleDataComponentEvents, filterWebhookEventsByComponentFolder } from "./lib";

export const NEW_APP_VERSION_TYPES = {
  WEB: "web",
  PLUGIN_FIGMA: "plugin-figma",
};

export const ZSegmentEventData = z.object({
  workspaceId: z.string(),
  userId: z.string(),
  application: z.union([z.literal("web_app"), z.literal("figma_plugin"), z.literal("unknown")]),
  version: z.union([z.literal("legacy"), z.literal("NS")]),
});

/**
 * Emit when a new app version is released and requries a reload
 * from the user.
 */
const ZNewAppVersionReleased = z.object({
  Data: z.object({
    app: z.union([z.literal(NEW_APP_VERSION_TYPES.WEB), z.literal(NEW_APP_VERSION_TYPES.PLUGIN_FIGMA)]),
  }),
});
export const newAppVersionReleased = createDittoEvent({
  name: "NewAppVersionReleased",
  data: ZNewAppVersionReleased.shape.Data,
  targets: [
    {
      type: "websocket",
      // Send to everyone
      identifyClient: () => true,
    },
  ],
});

/**
 * Emit when any set of fields on one or more components is updated.
 */
const ZComponentsUpdated = z.object({
  Data: z.object({
    senderUserId: z.string(),
    fromPlugin: z.boolean().optional(),
    components: z.array(z.object({ _id: ZObjectId })),
    deletedComponentIds: z.array(ZCreatableId),
    workspaceId: ZCreatableId,
  }),
});
export const componentsUpdated = createDittoEvent({
  name: "ComponentUpdated",
  data: ZComponentsUpdated.shape.Data,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceCompSubscription?.toString() === data.workspaceId.toString(),
    },
  ],
});

const ZComponentTextChange = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    textBefore: z.string(),
    textAfter: z.string(),
  }),
  webhookData: z.object({
    componentId: ZCreatableId, // this should be the component's API ID
    folderId: z.string().nullable(), // this should be the folder's API ID
    textBefore: z.string(),
    textAfter: z.string(),
  }),
});
export const componentTextChange = createDittoEvent({
  name: "Component_TextChange",
  data: ZComponentTextChange.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentTextChange>["webhookData"] => ({
        componentId: d.componentApiId,
        folderId: d.folderApiId,
        textBefore: d.textBefore,
        textAfter: d.textAfter,
      }),
    },
  ],
});
const ZComponentStatusChange = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    statusBefore: z.string(),
    statusAfter: z.string(),
  }),
  webhookData: z.object({
    componentId: ZCreatableId, // this should be the component's API ID
    folderId: z.string().nullable(), // this should be the folder's API ID
    statusBefore: z.string(),
    statusAfter: z.string(),
  }),
});
export const componentStatusChange = createDittoEvent({
  name: "Component_StatusChange",
  data: ZComponentStatusChange.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentStatusChange>["webhookData"] => ({
        componentId: d.componentApiId,
        folderId: d.folderApiId,
        statusBefore: d.statusBefore,
        statusAfter: d.statusAfter,
      }),
    },
  ],
});
const ZComponentDeveloperIdChange = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    apiIdBefore: z.string(),
    apiIdAfter: z.string(),
  }),
  webhookData: z.object({
    folderId: z.string().nullable(), // this should be the folder's API ID
    componentIdBefore: z.string(),
    componentIdAfter: z.string(),
  }),
});
export const componentDeveloperIdChange = createDittoEvent({
  name: "Component_IdChange",
  data: ZComponentDeveloperIdChange.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentDeveloperIdChange>["webhookData"] => ({
        folderId: d.folderApiId,
        componentIdBefore: d.apiIdBefore,
        componentIdAfter: d.apiIdAfter,
      }),
    },
  ],
});

const ZComponentCreated = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    userId: z.string(),
    creationType: ZComponentCreationType,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    name: z.string(),
    text: z.string(),
    status: z.string(),
    notes: z.string(),
    tags: z.array(z.string()),
  }),
  webhookData: z.object({
    componentId: z.string(),
    // this should be the folder's API ID
    folderId: z.string().nullable(),
    name: z.string(),
    text: z.string(),
    status: z.string(),
    notes: z.string(),
    tags: z.array(z.string()),
  }),
  segmentData: z.object({
    component_id: z.string(),
    action: ZComponentCreationType,
  }),
});
export const componentCreated = createDittoEvent({
  name: "Component_Creation",
  data: ZComponentCreated.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentCreated>["webhookData"] => ({
        folderId: d.folderApiId,
        componentId: d.componentApiId,
        name: d.name,
        text: d.text,
        status: d.status,
        notes: d.notes,
        tags: d.tags,
      }),
    },
    {
      type: "segment",
      segmentEventName: "Component Created",
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d): z.infer<typeof ZComponentCreated>["segmentData"] => ({
        component_id: d.componentId.toString(),
        action: d.creationType,
      }),
    },
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceCompSubscription?.toString() === data.workspaceId.toString(),
    },
  ],
});

const ZComponentsCreated = z.object({
  data: z.object({
    componentIds: z.array(ZCreatableId),
    componentNamesMap: ZComponentNamesMap,
    workspaceId: ZCreatableId,
    userId: z.string(),
    creationType: ZComponentCreationType,
  }),
  segmentData: z.object({
    component_ids: z.array(z.string()),
    action: ZComponentCreationType,
  }),
});
export const componentsCreated = createDittoEvent({
  name: "Components_Creation",
  data: ZComponentsCreated.shape.data,
  targets: [
    {
      type: "segment",
      segmentEventName: "Components Created",
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d): z.infer<typeof ZComponentsCreated>["segmentData"] => ({
        component_ids: d.componentIds.map((id) => id.toString()),
        action: d.creationType,
      }),
    },
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceCompSubscription?.toString() === data.workspaceId.toString(),
    },
  ],
});

const ZComponentDeleted = z.object({
  data: z.object({
    workspaceId: ZCreatableId,
    componentId: ZCreatableId,
    componentApiId: z.string(),
    folderId: ZCreatableId.nullable(),
    folderApiId: z.string().nullable(),
    name: z.string(),
  }),
  webhookData: z.object({
    componentId: z.string(),
    folderId: z.string().nullable(), // this should be the folder's API ID
    name: z.string(),
  }),
});
export const componentDeleted = createDittoEvent({
  name: "Component_Deletion",
  data: ZComponentDeleted.shape.data,
  targets: [
    {
      type: "webhook",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        componentId: d.componentId,
      }),
      filterEvents: async (data, filters, context) =>
        (await filterWebhookEventsByComponentFolder({ folderId: data.folderId }, filters, context)) &&
        (await filterOutSampleDataComponentEvents({ componentId: data.componentId }, filters, context)),
      formatWebhookData: (d): z.infer<typeof ZComponentDeleted>["webhookData"] => ({
        folderId: d.folderApiId,
        componentId: d.componentApiId,
        name: d.name,
      }),
    },
  ],
});

// MARK: - Text Item Events

const ZTextItemsCreated = ZSegmentEventData.extend({
  textItemIds: z.array(z.string()),
  projectId: z.string(),
  source: ZTextItemCreationSource,
});

export type ITextItemsCreated = z.infer<typeof ZTextItemsCreated>;

export const textItemsCreated = createDittoEvent({
  name: "TextItemsCreated",
  data: ZTextItemsCreated,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId &&
        webSocketClient.docSubscription === data.projectId,
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_CREATED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZLegacyTextITemsUpdated = z.object({
  data: z.object({
    from: z.enum(["web_app", "figma_plugin", "unknown"]),
    ids: z.array(z.string()),
    projectId: z.string(),
    projectName: z.string(),
    updates: z.object({
      assignee: z
        .object({
          _id: z.string().nullable(),
          newAssigneeUserId: z.string().nullable(),
          newAssigneeName: z.string().nullable(),
        })
        .optional(),
      tags: z
        .object({
          tagsAdded: z.array(z.string()),
          tagsDeleted: z.array(z.string()),
        })
        .optional(),
      status: z
        .object({
          status: ZActualComponentStatus,
          textItemText: z.string(),
        })
        .optional(),
      characterLimit: z.number().nullable().optional(),
    }),
    user: z.object({
      userId: z.string(),
      name: z.string(),
    }),
    workspaceId: z.string(),
  }),
});
export const legacyTextItemsUpdated = createDittoEvent({
  name: "TextItemsUpdated",
  data: ZLegacyTextITemsUpdated.shape.data,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_STATUS_CHANGED,
      filterEvents: async (data) => data.updates.status !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        doc_id: d.projectId,
        text_item_ids: d.ids,
        status: d.updates.status,
        application: d.from,
      }),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.MULTIPLE_COMPS_TAGS_UPDATED,
      filterEvents: async (data) => data.updates.tags !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        doc_id: d.projectId,
        workspace_id: d.workspaceId,
        num_comps: d.ids.length,
        from: d.from,
      }),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_ASSIGNED,
      filterEvents: async (data) => data.updates.assignee !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        project_id: d.projectId,
        text_item_ids: d.ids,
        assignee: d.updates.assignee?.newAssigneeUserId,
        count: d.ids.length,
      }),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_ASSIGNED,
      filterEvents: async (data) => data.updates.tags !== undefined,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.user.userId }),
      formatSegmentData: (d) => ({
        project_id: d.projectId,
        text_item_ids: d.ids,
        assignee: d.updates.assignee?.newAssigneeUserId,
        count: d.ids.length,
      }),
    },
    {
      type: "slack",
      getContext: (d) => ({ workspaceId: d.workspaceId, projectId: d.projectId }),
      filterEvents: async (d) => !!d.updates.status,
      formatSlackData: async (d) => {
        return slackMessageTypes.componentStatusChanged(
          d.user.name,
          d.updates.status!.status,
          d.updates.status!.textItemText,
          d.projectId,
          d.projectName,
          false,
          d.ids.length
        );
      },
    },
    {
      type: "slack",
      getContext: (d) => ({ workspaceId: d.workspaceId, projectId: d.projectId }),
      filterEvents: async (d) => !!d.updates.assignee?._id,
      formatSlackData: async (d) => {
        return slackMessageTypes.componentAssigned(
          d.user.name,
          d.updates.assignee?.newAssigneeName || "",
          "",
          d.projectId,
          d.projectName,
          false,
          d.ids.length
        );
      },
    },
    {
      type: "websocket",
      channel: REDIS_CHANNELS.TEXT_ITEM_UPDATE_CHANNEL,
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString(),
      formatWebsocketData: (d) => ({
        docId: d.projectId,
        textItemIds: d.ids,
        senderUserId: d.user.userId,
        fromPlugin: d.from === "figma_plugin",
        // backwards compatibility with outdated clients / backend processes still rolling over
        components: [],
      }),
    },
  ],
});

const ZTextItemsUpdated = z.object({
  application: z.union([z.literal("web_app"), z.literal("figma_plugin"), z.literal("unknown")]),
  textItemIds: z.array(ZObjectId),
  projectId: ZObjectId,
  workspaceId: ZObjectId,
  userObjectId: ZObjectId,
});

export type ITextItemsUpdated = z.infer<typeof ZTextItemsUpdated>;

export const textItemsUpdated = createDittoEvent({
  name: "textItemsUpdated",
  data: ZTextItemsUpdated,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        webSocketClient.docSubscription === data.projectId.toString(),
    },
  ],
});

export const ZTextItemsMoved = z.object({
  application: z.union([z.literal("web_app"), z.literal("figma_plugin"), z.literal("unknown")]),
  projectId: z.string(),
  workspaceId: z.string(),
  userObjectId: ZObjectId,
  actions: z.array(ZMoveTextItemsAction),
});

export type ITextItemsMoved = z.infer<typeof ZTextItemsMoved>;

export const textItemsMoved = createDittoEvent({
  name: "TextItemsMoved",
  data: ZTextItemsMoved,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId === data.workspaceId && webSocketClient.docSubscription === data.projectId,
    },
  ],
});

export const textItemResolved = createDittoEvent({
  name: "Figma Sync Conflict Resolved",
  data: ZSegmentEventData.extend({
    projectId: ZObjectId,
    textItemId: z.string(),
    selection: z.union([z.literal("ditto"), z.literal("figma")]),
    selectedFigmaNodeId: z.string().nullable(),
  }),
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.FIGMA_SYNC_CONFLICTS_RESOLVED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZTextItemUnlinked = z.object({
  workspaceId: z.string(),
  projectId: z.string(),
  oldTextItemId: z.string(),
  newTextItemId: z.string(),
  figmaNodeId: z.string(),
});

export const textItemUnlinked = createDittoEvent({
  name: "Text Item Unlinked",
  data: ZTextItemUnlinked,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId === data.workspaceId && webSocketClient.docSubscription === data.projectId,
    },
  ],
});

const ZTextItemsDeleted = ZSegmentEventData.extend({
  projectId: z.string(),
  textItemIds: z.array(z.string()),
});

export const textItemsDeleted = createDittoEvent({
  name: "Text Items Deleted",
  data: ZTextItemsDeleted,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId === data.workspaceId && webSocketClient.docSubscription === data.projectId,
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_ITEMS_DELETED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZFigmaTextNodesUnlinked = ZSegmentEventData.extend({
  data: z.array(
    z.object({
      figmaNodeId: z.string(),
      textItemId: z.string(),
    })
  ),
  projectId: z.string(),
});

export const figmaTextNodesUnlinked = createDittoEvent({
  name: "FigmaTextNodesUnlinked",
  data: ZFigmaTextNodesUnlinked,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId === data.workspaceId && webSocketClient.docSubscription === data.projectId,
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.FIGMA_TEXT_NODES_UNLINKED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d) => ({
        textItemIds: Array.from(new Set(d.data.map((x) => x.textItemId))),
        figmaNodeIds: Array.from(new Set(d.data.map((x) => x.figmaNodeId))),
        userId: d.userId,
        workspaceId: d.workspaceId,
        projectId: d.projectId,
      }),
    },
  ],
});

const ZTextItemVariantStatusChanged = ZSegmentEventData.extend({
  textItemId: z.string(),
  variantId: z.string(),
  status: z.string(),
  projectId: z.string().optional(),
  count: z.number(),
});
export const textItemVariantStatusChanged = createDittoEvent({
  name: "Variant Text Statuses Updated",
  data: ZTextItemVariantStatusChanged,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.VARIANT_TEXT_STATUS_UPDATED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZTextItemVariantTextChanged = ZSegmentEventData.extend({
  textItemId: z.string(),
  variantId: z.string(),
  projectId: z.string().optional(),
  count: z.number(),
});

export const textItemVariantTextChanged = createDittoEvent({
  name: "Variant Text Changed",
  data: ZTextItemVariantTextChanged,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.VARIANT_TEXT_UPDATED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZTextItemVariantAttached = ZSegmentEventData.extend({
  projectId: z.string().optional(),
  variantId: z.string(),
  textItemId: z.string(),
});

export const textItemVariantAttached = createDittoEvent({
  name: "Variants Attached To Text Item",
  data: ZTextItemVariantAttached,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.VARIANTS_ATTACHED_TO_TEXT_ITEM,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZTextItemVariantRemoved = ZSegmentEventData.extend({
  projectId: z.string(),
  userId: z.string(),
  workspaceId: z.string(),
  variantId: z.string(),
  textItemId: z.string(),
  version: z.union([z.literal("legacy"), z.literal("NS")]),
  application: z.union([z.literal("web_app"), z.literal("figma_plugin"), z.literal("unknown")]),
  count: z.number(),
});

export const textItemVariantRemoved = createDittoEvent({
  name: "Variants Removed From Text Item",
  data: ZTextItemVariantRemoved,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        webSocketClient.docSubscription === data.projectId.toString(),
    },
    {
      type: "segment",
      segmentEventName: SegmentEvents.VARIANTS_REMOVED_FROM_TEXT_ITEM,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZTextSuggestionAccepted = ZSegmentEventData.extend({
  projectId: z.string(),
  textItemId: z.string(),
});

export const textSuggestionAccepted = createDittoEvent({
  name: "Text Suggestion Accepted",
  data: ZTextSuggestionAccepted,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.TEXT_SUGGESTION_ACCEPTED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZBlockSuggestionAccepted = ZSegmentEventData.extend({
  projectId: z.string(),
  textItemIds: z.array(z.string()),
});

export const blockSuggestionAccepted = createDittoEvent({
  name: "Block Suggestion Accepted",
  data: ZBlockSuggestionAccepted,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.BLOCK_SUGGESTION_ACCEPTED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZInstancesAutomaticallyLinked = ZSegmentEventData.extend({
  projectId: z.string(),
  textItemIds: z.array(z.string()),
});

export const instancesAutomaticallyLinked = createDittoEvent({
  name: "Instances Automatically Linked",
  data: ZInstancesAutomaticallyLinked,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.INSTANCES_AUTOMATICALLY_LINKED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

// MARK: - Types For Background Jobs Events

const ZBaseBackgroundJob = z.object({
  id: z.number().or(z.string()),
  jobName: z.string(),
  workspaceId: z.string(),
  projectId: z.string().optional(),
});

const ZBackgroundJobCompleted = ZBaseBackgroundJob.extend({
  status: z.literal("completed"),
  returnValue: z.any(),
});

const ZBackgroundJobFailed = ZBaseBackgroundJob.extend({
  status: z.literal("failed"),
  failedReason: z.string().optional(),
});

const ZBackgroundJobStarted = ZBaseBackgroundJob.extend({
  status: z.literal("active"),
});

const ZBackgroundJob = z.discriminatedUnion("status", [
  ZBackgroundJobCompleted,
  ZBackgroundJobFailed,
  ZBackgroundJobStarted,
]);

/**
 * Emit when a background job is updated.
 */
export const backgroundJobUpdated = createDittoEvent({
  name: "BackgroundJobUpdated",
  data: ZBackgroundJob,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) => webSocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const fetchedProjectNamesViaApi = createDittoEvent({
  name: "Fetched project names via API",
  data: z.object({
    apiVersion: z.number(),
    workspaceId: ZCreatableId,
    userId: z.string(),
    isDittoCLIRequest: z.boolean(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        userId: d.userId,
      }),
    },
  ],
});

export const firstApiFetch = createDittoEvent({
  name: "First API Fetch",
  data: z.object({
    workspaceId: ZCreatableId,
    userId: z.string(),
    timeSinceWorkspaceCreated: z.number(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        userId: d.userId,
      }),
    },
  ],
});

export const firstDeveloperJoined = createDittoEvent({
  name: "First Developer Joined",
  data: z.object({
    workspaceId: ZCreatableId,
    userId: z.string(),
    application: z.union([z.literal("web_app"), z.literal("figma_plugin")]),
    timeSinceWorkspaceCreated: z.number(),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.workspaceId,
        userId: d.userId,
      }),
    },
  ],
});

export const onboardingResponses = createDittoEvent({
  name: "Onboarding Responses",
  data: z.object({
    user: ZUser.pick({
      workspaceId: true,
      userId: true,
      email: true,
    }),
    referralSource: z.string(),
    dittoUseCaseResponses: z.string(),
    application: z.union([z.literal("web_app"), z.literal("figma_plugin")]),
  }),
  targets: [
    {
      type: "segment",
      getContext: (d) => ({
        workspaceId: d.user.workspaceId,
        userId: d.user.userId,
      }),
    },
  ],
});

export const ZConcurrentUserListUpdateData = z.object({
  workspaceId: z.string(),
  pageKey: z.string(),
  users: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
      picture: z.string().optional().nullable(),
    })
  ),
});

export type ConcurrentUserListUpdateData = z.infer<typeof ZConcurrentUserListUpdateData>;

export const concurrentUserListUpdate = createDittoEvent({
  name: "Concurrent User List Update",
  data: ZConcurrentUserListUpdateData,
  targets: [
    {
      type: "websocket",
      // emit the event to everyone in the workspace; front-end clients can decide whether or not
      // they care about a given event according to the `pageKey` value
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString(),
    },
  ],
});

export const ZGroupsUnlinked = z.object({
  groups: z.array(z.string()),
  docId: z.string(),
});

export const groupsUnlinked = createDittoEvent({
  name: "Groups have been unlinked",
  data: ZGroupsUnlinked,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) => webSocketClient.docSubscription?.toString() === data.docId.toString(),
    },
  ],
});

export const ZGroupsImported = z.object({
  newFrameIdsMap: z.record(z.string()),
  docId: z.string(),
});

export const groupsImported = createDittoEvent({
  name: "GroupsImported",
  data: ZGroupsImported,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) => webSocketClient.docSubscription?.toString() === data.docId.toString(),
    },
  ],
});

export const ZBlockEvent = z.object({
  blockId: z.string(),
  groupId: z.string(),
  newName: z.string(),
  documentId: z.string(),
});

export const newBlock = createDittoEvent({
  name: "New block created",
  data: ZBlockEvent,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.documentId,
    },
  ],
});

export const updateBlock = createDittoEvent({
  name: "Update block",
  data: ZBlockEvent,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.documentId,
    },
  ],
});

// Order matters here!
export const ImportProgressSteps = [
  "frontend-start",
  "request-sent",
  "job-start",
  "figma-data-fetched",
  "save-project-get-doc",
  "frame-check-complete",
  "generate-component-map-complete",
  "generate-update-groups-complete",
  "check-unknown-figma-nodes-complete",
  "generate-component-upsert-commands-complete",
  "resync-complete",
  "job-end",
  "finished",
] as const;
export const ZImportProgressSteps = z.enum(ImportProgressSteps);

export const ZImportProgress = z.object({
  step: ZImportProgressSteps,
  // This is the sub, "userId" on the Users collection
  userId: z.string(),
  importJobId: z.string(),
});

export const importProgress = createDittoEvent({
  name: "Import progress",
  data: ZImportProgress,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.userId === data.userId,
    },
  ],
});

export const ZAttachMethod = z.enum(["import", "auto-component", "manual", "figma-variables", "swap"]);

export const ZComponentAttachedToTextItems = z.object({
  userId: z.string(),
  projectId: z.string(),
  workspaceId: z.string(),
  componentId: z.string(),
  textItemIds: z.array(z.string()),
  action: ZAttachMethod,
});

export const componentAttachedToTextItems = createDittoEvent({
  name: "Component attached to text items",
  data: ZComponentAttachedToTextItems,
  targets: [
    {
      type: "websocket",
      identifyClient: (webSocketClient, data) =>
        webSocketClient.workspaceId?.toString() === data.workspaceId.toString(),
    },
    {
      type: "segment",
      segmentEventName: COMPONENT_ATTACHED_TO_TEXT_ITEMS,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
      formatSegmentData: (d) => ({
        text_item_ids: d.textItemIds,
        component_id: d.componentId,
        project_id: d.projectId,
        action: d.action,
      }),
    },
  ],
});

export const ZGroupUpdatedEvent = z.object({
  groupId: z.string(),
  documentId: z.string(),
});

export const groupUpdated = createDittoEvent({
  name: "Group updated",
  data: ZGroupUpdatedEvent,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.documentId,
    },
  ],
});

export const ZAutoAttachComponentsStarted = z.object({
  projectId: z.string(),
  workspaceId: z.string(),
});

export const autoAttachComponentsStarted = createDittoEvent({
  name: "Auto Attach Components Started",
  data: ZAutoAttachComponentsStarted,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const ZAutoAttachComponentsFinished = z.object({
  projectId: z.string(),
  workspaceId: z.string(),
  textItemsAttached: z.array(z.string()),
});

export const autoAttachComponentsFinished = createDittoEvent({
  name: "Auto Attach Components Finished",
  data: ZAutoAttachComponentsFinished,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const ZComponentRename = z.object({
  componentId: ZCreatableId,
  newNames: z.object({
    groupName: z.string(),
    blockName: z.string(),
    componentName: z.string(),
  }),
  oldNames: z.object({
    groupName: z.string(),
    blockName: z.string(),
    componentName: z.string(),
  }),
});

export type IComponentRename = z.infer<typeof ZComponentRename>;

export const ComponentRenameMap = z.record(z.string(), ZComponentRename);

export type IComponentRenameMap = z.infer<typeof ComponentRenameMap>;

export const componentsRenamed = createDittoEvent({
  name: "ComponentsRenamed",
  data: z.object({
    workspaceId: ZCreatableId,
    renamesMap: ComponentRenameMap,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

// MARK: - Figma Cache Ditto Events

export const figmaCacheRefreshStarted = createDittoEvent({
  name: "Figma Cache Refresh Started",
  data: z.object({
    fileId: z.string(),
    branchId: z.string().nullable(),
    workspaceId: ZCreatableId,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const figmaCacheRefreshCompleted = createDittoEvent({
  name: "Figma Cache Refresh Completed",
  data: z.object({
    fileId: z.string(),
    branchId: z.string().nullable(),
    workspaceId: ZCreatableId,
    didUpdate: z.boolean(),
    lastModified: z.date(),
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.workspaceId === data.workspaceId,
    },
  ],
});

export const newFigmaSyncActions = createDittoEvent({
  name: "New Figma Sync Actions",
  data: z.object({
    fileId: z.string(),
    projectId: ZCreatableId,
    branchId: z.string().nullable(),
    workspaceId: ZCreatableId,
    actions: z.array(ZAction),
  }),
  targets: [
    {
      type: "websocket",
      // TODO: This emits to everyone in a workspace in every connection. We should filter this down to only the relevant connections
      // (i.e. those in the figma file and have the plugin open in figma / those who have the project open in the web app)
      identifyClient: (websocketClient, data) =>
        websocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        websocketClient.docSubscription === data.projectId.toString(),
    },
  ],
});

// MARK: - Ditto Project Events

const ZProjectBlocksCreated = ZSegmentEventData.extend({
  projectId: ZCreatableId,
  blockIds: z.array(z.string()),
  source: ZBlockCreationSource,
});

export type IProjectBlocksCreated = z.infer<typeof ZProjectBlocksCreated>;

export const projectBlocksCreated = createDittoEvent({
  name: "projectBlocksCreated",
  data: ZProjectBlocksCreated,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.PROJECT_BLOCKS_CREATED,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZProjectConnectedToFigma = ZSegmentEventData.extend({
  projectId: ZCreatableId,
  figmaFileId: z.string(),
});

export type IProjectConnectedToFigma = z.infer<typeof ZProjectConnectedToFigma>;

export const projectConnectedToFigma = createDittoEvent({
  name: "projectConnectedToFigma",
  data: ZProjectConnectedToFigma,
  targets: [
    {
      type: "segment",
      segmentEventName: SegmentEvents.PROJECT_CONNECTED_TO_FIGMA,
      getContext: (d) => ({ workspaceId: d.workspaceId, userId: d.userId }),
    },
  ],
});

const ZProjectBlocksUpdated = z.object({
  workspaceId: ZCreatableId,
  projectId: ZCreatableId,
  blocks: z.array(ZDittoProjectBlock),
});

export type IProjectBlocksUpdated = z.infer<typeof ZProjectBlocksUpdated>;

export const projectBlocksUpdated = createDittoEvent({
  name: "projectBlocksUpdated",
  data: ZProjectBlocksUpdated,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.projectId,
    },
  ],
});

const ZProjectBlocksDeleted = z.object({
  workspaceId: ZCreatableId,
  projectId: ZCreatableId,
  blockIds: z.array(z.string()),
});

export type IProjectBlocksDeleted = z.infer<typeof ZProjectBlocksDeleted>;

export const projectBlocksDeleted = createDittoEvent({
  name: "projectBlocksDeleted",
  data: ZProjectBlocksDeleted,
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) => websocketClient.docSubscription?.toString() === data.projectId,
    },
  ],
});

export const projectActualChangesCreated = createDittoEvent({
  name: "projectActualChangesCreated",
  data: z.object({
    workspaceId: ZObjectId,
    projectId: ZObjectId,
    changeItemIds: z.array(ZObjectId),
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) =>
        websocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        websocketClient.docSubscription?.toString() === data.projectId.toString(),
    },
  ],
});

/**
 * A brand new comment thread has been created on a text item in a project.
 */
export const projectCommentThreadCreated = createDittoEvent({
  name: "projectCommentThreadCreated",
  data: z.object({
    workspaceId: ZObjectId,
    projectId: ZObjectId,
    commentThreadId: ZObjectId,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) =>
        websocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        websocketClient.docSubscription?.toString() === data.projectId.toString(),
    },
  ],
});

/**
 * An existing comment thread on a text item in a project has been updated, such as a new reply was added.
 * This is triggered when any change to an existing thread has been made, except for resolution.
 * For resolution, use `commentThreadResolutionUpdated`.
 */
export const projectCommentThreadUpdated = createDittoEvent({
  name: "projectCommentThreadUpdated",
  data: z.object({
    workspaceId: ZObjectId,
    projectId: ZObjectId,
    commentThreadId: ZObjectId,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) =>
        websocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        websocketClient.docSubscription?.toString() === data.projectId.toString(),
    },
  ],
});

/**
 * A comment thread on a text item in a project has been resolved or unresolved.
 *
 * While we could also use the more general `commentThreadUpdated` for this,
 * the FE will need to look up the entire updated thread when that event is received.
 * In the case of resulution, it's just a simple boolean field that changed,
 * so we can include that in the event and avoid an extra FE request.
 */
export const projectCommentThreadResolutionUpdated = createDittoEvent({
  name: "projectCommentThreadResolutionUpdated",
  data: z.object({
    workspaceId: ZObjectId,
    projectId: ZObjectId,
    commentThreadId: ZObjectId,
    isResolved: z.boolean(),
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) =>
        websocketClient.workspaceId?.toString() === data.workspaceId.toString() &&
        websocketClient.docSubscription?.toString() === data.projectId.toString(),
    },
  ],
});

// MARK: - Workspace Events

/**
 * A variant has been created in a workspace.
 */
export const variantCreated = createDittoEvent({
  name: "variantCreated",
  data: z.object({
    workspaceId: ZObjectId,
    variantId: ZObjectId,
  }),
  targets: [
    {
      type: "websocket",
      identifyClient: (websocketClient, data) =>
        websocketClient.workspaceId?.toString() === data.workspaceId.toString(),
    },
  ],
});
